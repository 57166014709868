import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  lighten,
  makeStyles,
  ThemeProvider,
  createTheme
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import InputMask from "react-input-mask";
import Slide from "@material-ui/core/Slide";
import { green } from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import api from "services/api";
import Swal from "sweetalert2";
import { getToken } from "services/auth";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";

const schema = yup
  .object({
    nome: yup.string().required("Nome é obrigatório"),
    cpf: yup.string().required("CPF é obrigatório"),
    role: yup.string().required("Campo obrigatório"),
    escala: yup.string().required(""),
    senha: yup
      .string()
      .required("Senha é obrigatória")
      .min(8, "A senha dever ter no mínimo 8 caracteres"),
    confirmaSenha: yup
      .string()
      .required("Senha é obrigatória")
      .oneOf([yup.ref("senha")], "As senhas não correspondem"),
    email: yup.string().email("Digite um email válido")
  })
  .required();

const useStyles = makeStyles(theme => ({
  root: {
    margin: "25px 50px",
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
    "& .MuiFormHelperText-root": {
      color: "red"
    }
  },
  appBar: {
    position: "relative"
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1
  },
  h1: {
    marginTop: "30px",
    marginLeft: "25px"
  },
  textFieldLarge: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: 400
  },
  font: {
    fontFamily: "Helvetica"
  },
  formControl: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    minWidth: 120
  },
  textSave: {
    color: "white"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  palette: {
    primary: green
  }
});

export default function UpdateUsuario(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const escalas = props.escalas;
  const dataRoles = props.dataRoles;

  const onChangeItem = props.onChangeItem;

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const [open, setOpen] = useState(false);
  const [nome, sendNome] = useState(props.nome);
  const [cpf, sendCPF] = useState(props.cpf);
  const [is_terceiro, sendIs_terceiro] = useState(props.is_terceiro);
  const [password, sendPassword] = useState(props.password);
  const [role_id, sendRole_id] = useState(props.role);
  const [matricula, sendMatricula] = useState(props.matricula);
  const [cargo, sendCargo] = useState(props.cargo);
  const [rg, sendRG] = useState(props.rg);
  const [data_nasc, sendData_nasc] = useState(props.data_nasc);
  const [sexo, sendSexo] = useState(props.sexo);
  const [telefone, sendTelefone] = useState(props.telefone);
  const [celular, sendCelular] = useState(props.celular);
  const [email, sendEmail] = useState(props.email);
  const [descricao, sendDescricao] = useState(props.descricao);
  const [endereco, sendEndereco] = useState(props.endereco);
  const [bairro, sendBairro] = useState(props.bairro);
  const [numero, sendNumero] = useState(props.numero);
  const [cep, sendCEP] = useState(props.cep);
  const [cidade, sendCidade] = useState(props.cidade);
  const [estado, sendEstado] = useState(props.estado);
  const [data_admissao, sendData_admissao] = useState(props.data_admissao);

  const [ativo, setAtivo] = useState(props.ativo);
  const [centrodecustos_id, sendCentroDeCustos_id] = useState(
    props.centrodecustos_id
  );
  const [id_escala, sendIdEscala] = useState(props.id_escala);

  const headers = { Authorization: "Bearer " + getToken() };

  const handleUp = () => {
    const id = props.id;
    setIsLoading(true);

    try {
      api
        .patch(
          `ponto/usuarios/${id}`,
          {
            nome: nome,
            cpf: cpf.replace(/[^0-9]/g, ""),
            password: password,
            role: role_id,
            matricula: matricula,
            cargo: cargo,
            rg: rg,
            data_nasc: data_nasc,
            sexo: sexo,
            telefone: telefone ? telefone.replace(/[^0-9]/g, "") : null,
            celular: celular ? celular.replace(/[^0-9]/g, "") : null,
            email: email,
            descricao: descricao,
            endereco: endereco,
            bairro: bairro,
            numero: numero,
            cep: cep ? cep.replace(/[^0-9]/g, "") : null,
            cidade: cidade,
            estado: estado,
            data_admissao: data_admissao,
            is_terceiro: is_terceiro,
            id_empresa: 1,
            excluido: false,
            centrodecustos_id: centrodecustos_id,
            ativo: ativo,
            id_escala,
            id_escala
          },
          { headers }
        )
        .then(res => {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            confirmButtonColor: "#3085d6",
            title: "Uhul!",
            text: "Usuário atualizado."
          });
          handleClose();
          onChangeItem({ id: id, nome: nome });
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não poi possível atualizar o usuário"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(false);
    onChangeItem({ id: id, nome: nome });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <IconButton
        color="primary"
        aria-label="Editar Usuário"
        title="Editar Usuário"
        onClick={handleClickOpen}
      >
        <EditIcon fontSize="large" />
      </IconButton>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth
        aria-labelledby="editar-usuário"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Editar Usuário
            </Typography>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                className={(classes.margin, classes.textSave)}
                onClick={handleSubmit(handleUp)}
              >
                Salvar
              </Button>
            </ThemeProvider>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.font}>
          {!isLoading ? (
            <div>
              <Card className={classes.root}>
                <h1 className={classes.h1}>DADOS PRINCIPAIS</h1>
                <CardContent>
                  <TextField
                    className={classes.textFieldLarge}
                    autoFocus
                    id="name"
                    label="Nome"
                    type="text"
                    variant="outlined"
                    defaultValue={nome}
                    error={errors.nome ? true : false}
                    helperText={errors.nome?.message}
                    {...register("nome", {
                      required: true,
                      onChange: e => sendNome(e.target.value)
                    })}
                  />

                  <InputMask
                    mask="999.999.999-99"
                    maskChar=" "
                    type="text"
                    Label="CPF"
                    name="cpf"
                    variant="outlined"
                    defaultValue={cpf}
                    error={errors.cpf ? true : false}
                    helperText={errors.cpf?.message}
                    {...register("cpf", {
                      required: true,
                      onChange: e => sendCPF(e.target.value)
                    })}
                  >
                    {() => (
                      <TextField
                        className={classes.textFieldLarge}
                        label="CPF"
                        type="text"
                        name="cpf"
                        variant="outlined"
                        defaultValue={cpf}
                        error={errors.cpf ? true : false}
                        helperText={errors.cpf?.message}
                        {...register("cpf", {
                          required: true,
                          onChange: e => sendCPF(e.target.value)
                        })}
                      />
                    )}
                  </InputMask>

                  <TextField
                    className={classes.textFieldLarge}
                    id="standard-basic"
                    type="text"
                    label="RG"
                    variant="outlined"
                    defaultValue={rg}
                    onChange={e => {
                      sendRG(+e.target.value);
                    }}
                  />

                  <TextField
                    className={classes.textField}
                    id="data_nasc"
                    type="date"
                    label="Data de Nascimento"
                    variant="outlined"
                    defaultValue={data_nasc}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => {
                      sendData_nasc(e.target.value);
                    }}
                  />

                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Sexo</InputLabel>
                    <Select
                      label="Sexo"
                      defaultValue={sexo}
                      onChange={e => {
                        sendSexo(e.target.value);
                      }}
                    >
                      <MenuItem value="F">Feminino</MenuItem>
                      <MenuItem value="M">Masculino</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    className={classes.textField}
                    id="standard-basic"
                    type="text"
                    label="Matricula"
                    variant="outlined"
                    defaultValue={matricula}
                    onChange={e => {
                      sendMatricula(e.target.value);
                    }}
                  />

                  <TextField
                    className={classes.textField}
                    id="data_admissao"
                    type="date"
                    label="Data de Admissão"
                    variant="outlined"
                    defaultValue={data_admissao}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => {
                      sendData_admissao(e.target.value);
                    }}
                  />

                  <TextField
                    className={classes.textFieldLarge}
                    id="standard-basic"
                    type="text"
                    label="Cargo"
                    variant="outlined"
                    defaultValue={cargo}
                    onChange={e => {
                      sendCargo(e.target.value);
                    }}
                  />

                  <FormControl
                    className={classes.textFieldLarge}
                    variant="standard"
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Centro de Custos</InputLabel>
                      <Select
                        label="Centro de Custos"
                        defaultValue={centrodecustos_id}
                        value={centrodecustos_id}
                        onChange={e => {
                          sendCentroDeCustos_id(e.target.value);
                        }}
                      >
                        {props.centroCustos.map((centroCusto, index) => (
                          <MenuItem key={index} value={centroCusto.id}>
                            {centroCusto.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>

                  <FormControl variant="outlined" className={classes.textField}>
                    <InputLabel>Escala</InputLabel>
                    <Select
                      name="escala"
                      label="Escalas"
                      defaultValue={id_escala}
                      value={id_escala}
                      error={errors.escala ? true : false}
                      {...register("escala", {
                        required: true,
                        onChange: e => sendIdEscala(e.target.value)
                      })}
                    >
                      {escalas.map((escala, index) => (
                        <MenuItem key={index} value={escala.id}>
                          {" "}
                          {escala.nome}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.escala && (
                      <FormHelperText>Escala é obrigatória</FormHelperText>
                    )}
                  </FormControl>

                  <TextField
                    className={classes.formControl}
                    variant="outlined"
                    type="password"
                    label="Senha"
                    name="senha"
                    defaultValue={password}
                    error={errors.senha ? true : false}
                    helperText={errors.senha?.message}
                    {...register("senha", {
                      required: true,
                      onChange: e => sendPassword(e.target.value)
                    })}
                  />
                  <TextField
                    className={classes.control}
                    variant="outlined"
                    type="password"
                    label="Confirmar Senha"
                    name="confirmaSenha"
                    error={errors.confirmaSenha ? true : false}
                    helperText={errors.confirmaSenha?.message}
                    {...register("confirmaSenha", {
                      required: true
                    })}
                  />

                  <FormControl
                    variant="outlined"
                    className={classes.textFieldLarge}
                  >
                    <InputLabel>Nível de acesso</InputLabel>
                    <Select
                      name="role"
                      label="Nível de acesso"
                      value={role_id}
                      defaultValue={role_id}
                      error={errors.role ? true : false}
                      {...register("role", {
                        required: true,
                        onChange: e => sendRole_id(e.target.value),
                        shouldUnregister: true
                      })}
                    >
                      {dataRoles.map((roles, index) => (
                        <MenuItem key={index} value={roles.id}>
                          {" "}
                          {roles.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    // className={classes.textFieldLarge}
                    component="fieldset"
                  >
                    <FormLabel component="legend">É Terceiro?</FormLabel>
                    <Switch
                      color="primary"
                      focusVisibleClassName={classes.focusVisible}
                      checked={is_terceiro}
                      onChange={e => sendIs_terceiro(e.target.checked)}
                      disableRipple
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked
                      }}
                      {...props}
                    />
                  </FormControl>

                  <FormControl
                    component="fieldset"
                    // className={classes.textFieldLarge}
                  >
                    <FormLabel component="legend">Ativo?</FormLabel>
                    <Switch
                      color="primary"
                      focusVisibleClassName={classes.focusVisible}
                      checked={ativo}
                      disableRipple
                      onChange={e => setAtivo(e.target.checked)}
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked
                      }}
                      {...props}
                    />
                  </FormControl>
                </CardContent>
              </Card>

              <Card className={classes.root}>
                <h1 className={classes.h1}>CONTATO</h1>
                <CardContent>
                  <TextField
                    className={classes.textFieldLarge}
                    id="standard-basic"
                    type="text"
                    label="Email"
                    name="email"
                    variant="outlined"
                    defaultValue={email}
                    error={errors.email ? true : false}
                    helperText={errors.email?.message}
                    {...register("email", {
                      onChange: e => sendEmail(e.target.value)
                    })}
                  />

                  <InputMask
                    variant="outlined"
                    type="text"
                    mask="(99)9999-9999"
                    label="Telefone"
                    maskChar=" "
                    defaultValue={telefone}
                    onChange={e => {
                      sendTelefone(e.target.value);
                    }}
                  >
                    {() => (
                      <TextField
                        className={classes.textFieldLarge}
                        id="standard-basic"
                        type="text"
                        label="Telefone"
                        variant="outlined"
                      />
                    )}
                  </InputMask>

                  <InputMask
                    variant="outlined"
                    type="text"
                    mask="(99)99999-9999"
                    label="Celular"
                    maskChar=" "
                    defaultValue={celular}
                    onChange={e => {
                      sendCelular(e.target.value);
                    }}
                  >
                    {() => (
                      <TextField
                        className={classes.textFieldLarge}
                        id="standard-basic"
                        type="text"
                        label="Celular"
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </CardContent>
              </Card>

              <Card className={classes.root}>
                <h1 className={classes.h1}>ENDEREÇO</h1>
                <CardContent>
                  <InputMask
                    variant="outlined"
                    type="text"
                    label="CEP"
                    mask="99.999-999"
                    maskChar=" "
                    defaultValue={cep}
                    onChange={e => {
                      sendCEP(e.target.value);
                    }}
                  >
                    {() => (
                      <TextField
                        className={classes.textField}
                        id="standard-basic"
                        type="text"
                        label="CEP"
                        variant="outlined"
                      />
                    )}
                  </InputMask>

                  <TextField
                    className={classes.textFieldLarge}
                    id="standard-basic"
                    type="text"
                    label="Endereco"
                    variant="outlined"
                    defaultValue={endereco}
                    onChange={e => {
                      sendEndereco(e.target.value);
                    }}
                  />

                  <TextField
                    className={classes.textFieldLarge}
                    id="standard-basic"
                    type="text"
                    label="Bairro"
                    variant="outlined"
                    defaultValue={bairro}
                    onChange={e => {
                      sendBairro(e.target.value);
                    }}
                  />

                  <TextField
                    className={classes.textField}
                    id="standard-basic"
                    type="text"
                    label="Número"
                    variant="outlined"
                    defaultValue={numero}
                    onChange={e => {
                      sendNumero(+e.target.value);
                    }}
                  />

                  <TextField
                    className={classes.textFieldLarge}
                    id="standard-basic"
                    type="text"
                    label="Cidade"
                    variant="outlined"
                    defaultValue={cidade}
                    onChange={e => {
                      sendCidade(e.target.value);
                    }}
                  />

                  <TextField
                    className={classes.textField}
                    id="standard-basic"
                    type="text"
                    label="Estado"
                    variant="outlined"
                    defaultValue={estado}
                    inputProps={{ maxLength: 2 }}
                    onChange={e => {
                      sendEstado(e.target.value);
                    }}
                  />
                </CardContent>
              </Card>

              <Card className={classes.root}>
                <h1 className={classes.h1}>DADOS ADICIONAIS</h1>
                <CardContent>
                  <TextField
                    id="standard-basic"
                    type="text"
                    label="Descricao"
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    inputProps={{ maxLength: 500 }}
                    defaultValue={descricao}
                    onChange={e => {
                      sendDescricao(e.target.value);
                    }}
                  />
                </CardContent>
              </Card>
            </div>
          ) : (
            <PageLoader />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
