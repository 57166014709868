import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Box, DialogContentText} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import api from "services/api";
import Swal from "sweetalert2";
import {getToken} from "services/auth";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";

export default function DeleteUsuario(props){
  const [ open, setOpen ] = useState(false);
  const headers = {Authorization: "Bearer " + getToken()};

  const nome = props.nome;
  const onChangeItem = props.onChangeItem;

  const [ isLoading, setIsLoading ] = useState(true);

  const handleDelete = () => {
    const id = props.id;
    setIsLoading(true);

    try {
      api
        .delete(`ponto/usuarios/${id}`, {headers})
        .then(res => {
          setIsLoading(false);

          Swal.fire({
            icon: "success",
            confirmButtonColor: "#3085d6",
            title: "Uhul!",
            text: "Usuário excluido."
          });
          handleClose();
          onChangeItem({id: props.id, nome: nome});

        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos excluir o usuário"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(false);
    onChangeItem({id: props.id, nome: nome});

  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <IconButton
        color="secondary"
        aria-label="Excluir usuário"
        title="Excluir usuário"
        onClick={handleClickOpen}
      >
        <DeleteIcon fontSize="large" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="excluir-usuário"
        fullWidth
      >
        <DialogTitle id="excluir-usuário">
          Excluir Motivo
          <DialogContentText>
            Deseja excluir o usuário {nome}?
          </DialogContentText>
        </DialogTitle>
        <DialogActions>
          {!isLoading ? (
            <PageLoader />
          ) : (
            <div>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleDelete} color="secondary">
                Excluir
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
