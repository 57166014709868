import React from "react";
import {lighten, makeStyles} from "@material-ui/core/styles";
import moment from "moment";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import {Paper} from "@material-ui/core";
import {Box} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/Warning";
import Chip from "@material-ui/core/Chip";
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    marginBottom: "10px",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  button: {
    "& > *": {
      margin: theme.spacing(2)
    }
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200
  },
  ul: {
    listStyleType: "none",
    display: "flex",
    paddingRight: "14px"
  },
  li: {
    listStyleType: "none",
    display: "flex",
    paddingRight: "14px"
  },
  lista: {
    listStyleType: "none",
    display: "flex",
    paddingRight: "14px"
  },
  accordion: {
    backgroundColor: "#f4f4f7",
    margin: theme.spacing(2)
  },
  accordionDetails: {
    display: "block"
  },
  check: {
    color: "green"
  }
}));

export default function TablePonto(props){
  const classes = useStyles();
  const users = props.users;
  const dataInicio = props.dataInicio;
  const dataFim = props.dataFim;

  return (
    <Box className={classes.root}>
      <TableContainer component={Paper} className={classes.root}>
        <Table className={classes.table} aria-label="espelho-ponto">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Período</TableCell>
              <TableCell>Pendências</TableCell>
              <TableCell>Situação</TableCell>
              <TableCell style={{textAlign: "center"}}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, id) => {
              return (
                <TableRow key={id}>
                  <TableCell component="td" scope="row">
                    {user.nome}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(dataInicio).format("DD/MM/YYYY")} A{" "}
                    {moment(dataFim).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{justifyItems: "center"}}
                  >
                    {user.pendencias == 0 ? (
                      <CheckIcon className={classes.check} />
                    ) : (
                      <Tooltip
                        title="Existe ajuste de ponto pendente"
                        placement="right-end"
                      >
                        <WarningIcon style={{color: "orange"}} />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {user.statusEspelhoPonto == "ABERTO" ? (
                      <Chip
                        label={user.statusEspelhoPonto}
                        style={{backgroundColor: "orange", height: "20px"}}
                      />
                    ) : (
                      <Chip
                        label={user.statusEspelhoPonto}
                        style={{
                          backgroundColor: "green",
                          color: "white",
                          height: "20px"
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{textAlign: "center"}}
                  >
                    <Link
                      to={{
                        pathname: "espelho-ponto/perfil",
                        state: {
                          user: user.id,
                          dataInicio: dataInicio,
                          dataFim: dataFim,
                          statusEspelhoPonto: user.statusEspelhoPonto,
                          idEspelhoPonto: user.idEspelhoPonto
                        }
                      }}
                      style={{paddingRight: "20px"}}
                    >
                      {" "}
                      <span>Ver espelho ponto</span>
                    </Link>
                    <Link
                      to={{
                        pathname: "pontos/",
                        state: {
                          user: user.id,
                          dataInicio: dataInicio,
                          dataFim: dataFim
                        }
                      }}
                      style={{paddingLeft: "20px"}}
                    >
                      <span>Ver consulta ponto</span>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
