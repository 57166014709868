import React from "react";
import {Route, Switch, Redirect} from "react-router";
import {isAuthenticated} from "services/auth";

import Error404 from "./Pages/404";
import Login from "./Auth/Login";
import Dashboard from "./Pages/Dashboard";
import User from "./Pages/User";
import Motivos from "./Pages/Motivo";
import CentroCustos from "./Pages/CentroCustos";
import Ponto from "./Pages/Ponto";
import AjustePonto from "./Pages/Ajuste";
import Escalas from "./Pages/Escalas";
import EspelhoPonto from "./Pages/EspelhoPonto";
import EspelhoPontoUser from "./Pages/EspelhoPonto/DataEspelhoPonto";

const RestrictedRoute = ({component: Component, auth, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props =>
        auth === true ? <Component {...props} /> : <Redirect to="/login" />}
    />
  );
};

const Routes = () => {
  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute
          path="/dashboard"
          component={Dashboard}
          exact
          auth={isAuthenticated()}
        />
        <RestrictedRoute
          path="/users"
          component={User}
          exact
          auth={isAuthenticated()}
        />
        <RestrictedRoute
          path="/motivos"
          component={Motivos}
          exact
          auth={isAuthenticated()}
        />
        <RestrictedRoute
          path="/centrocustos"
          component={CentroCustos}
          exact
          auth={isAuthenticated()}
        />
        <RestrictedRoute
          path="/pontos"
          component={routeProps => <Ponto {...routeProps} />}
          exact
          auth={isAuthenticated()}
        />
        <RestrictedRoute
          path="/ajustes-pontos"
          component={AjustePonto}
          exact
          auth={isAuthenticated()}
        />
        <RestrictedRoute
          path="/escalas"
          component={Escalas}
          exact
          auth={isAuthenticated()}
        />
        <RestrictedRoute
          path={"/espelho-ponto"}
          component={EspelhoPonto}
          exact
          auth={isAuthenticated()}
        />
        <RestrictedRoute
          path={"/espelho-ponto/perfil"}
          component={routeProps => <EspelhoPontoUser {...routeProps} />}
          exact
          auth={isAuthenticated()}
        />

        <Route path="/login" component={Login} exact />
        <Redirect from="*" to="/login" />

        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
