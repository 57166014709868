import React, {useState} from "react";

import {Box} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {DialogContent} from "@material-ui/core";
import {DialogContentText} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

import {getToken} from "../../../services/auth";
import Swal from "sweetalert2";
import api from "../../../services/api";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";

export default function DeleteCentroCustos(props){
  const [ open, setOpen ] = useState(false);

  const [ isLoading, setIsLoading ] = useState(true);
  const headers = {Authorization: "Bearer " + getToken()};

  const id = props.id;
  const nome = props.nome;
  const onChangeItem = props.onChangeItem;

  const deleteCentroCustos = id => {
    setIsLoading(true);
    try {
      api
        .delete(`ponto/centro-custos/${id}`, {headers})
        .then(res => {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            confirmButtonColor: "#3085D6",
            title: "Uhul!",
            text: "Centro de Custos excluido."
          });
          handleClose();
          onChangeItem({id: id, nome: nome});
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085D6",
            title: "Oops...",
            text: "Não poi possível excluir o Centro de Custos"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(false);
    onChangeItem({id: id, nome: nome});
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box>
        <IconButton
          color="secondary"
          aria-label="Excluir Centro de Custos"
          title="Excluir Centro de Custos"
          onClick={handleClickOpen}
        >
          <DeleteIcon fontSize="large" />
        </IconButton>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="excluir-centro-custos"
        fullWidth
        scroll="body"
      >
        <DialogTitle id="excluir-centro-custos">
          {" "}
          Excluir Centro de Custo
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Deseja excluir {nome}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isLoading ? (
            <PageLoader />
          ) : (
            <div>
              <Button onClick={handleClose} color="secondary">
                Cancelar
              </Button>
              <Button onClick={e => deleteCentroCustos(id)} color="primary">
                Excluir
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
