import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import {Box} from "@material-ui/core";

import {withStyles} from "@material-ui/core/styles";
import {green, red} from "@material-ui/core/colors";

import Radio from "@material-ui/core/Radio";

import api from "services/api";
import Swal from "sweetalert2";
import {getToken, getUser} from "services/auth";
import moment from "moment";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: red[400],
    "&$checked": {
      color: red[600]
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);

export default function UpdateAjuste(props){
  const headers = {Authorization: "Bearer " + getToken()};
  const user = getUser();
  const [ selectedValue, setSelectedValue ] = React.useState("2");
  const [ open, setOpen ] = useState(false);
  const [ just, sendJust ] = useState("");
  const [ isLoading, setIsLoading ] = useState(true);
  const onChangeItem = props.onChangeItem;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const handleApprove = () => {
    setIsLoading(true);
    const id = props.id;
    const data = moment().format("yyy-MM-DD HH:MM:ss");

    try {
      api
        .post(
          `ponto/ajustes/${id}`,
          {
            status: selectedValue,
            obs_supervisor: just,
            id_supervisor: user.id,
            data_supervisor: data
          },
          {headers}
        )
        .then(res => {
          setIsLoading(false);
          Swal.fire({
            icon: res.data.status == true ? "success" : "warning",
            confirmButtonColor: "#3085d6",
            title: res.data.status == true ? "Uhul... 👍" : "Opss... 😞",
            text: res.data.message
          });
          handleClose();
          onChangeItem({id: id, nome: just});
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops... 😖",
            text: "Não foi possivel avaliar o ajuste de ponto"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(false);
    onChangeItem({id: id, nome: just});
  };

  return (
    <Box>
      <IconButton
        disabled={props.status}
        color="primary"
        aria-label="Avaliar Ajuste"
        title="Avaliar Ajuste"
        onClick={handleClickOpen}
      >
        <EditIcon fontSize="large" />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="avaliar-ajuste"
      >
        <DialogTitle id="avaliar-ajuste">Avaliar Ajuste</DialogTitle>
        <DialogContent>
          <div>
            <label>Aprovar</label>
            <GreenRadio
              checked={selectedValue === "2"}
              onChange={handleChange}
              value="2"
              name="radio-button-demo"
              inputProps={{"aria-label": "ajuste"}}
            />
            <label>Recusar</label>
            <RedRadio
              checked={selectedValue === "3"}
              onChange={handleChange}
              value="3"
              name="radio-button-demo"
              inputProps={{"aria-label": "ajuste"}}
            />
          </div>
          <TextField
            autoFocus
            required
            margin="dense"
            id="just"
            label="Justificativa"
            type="text"
            fullWidth
            defaultValue={just}
            onChange={e => {
              sendJust(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          {!isLoading ? (
            <PageLoader />
          ) : (
            <div>
              <Button onClick={handleClose} color="secondary">
                Cancelar
              </Button>
              <Button onClick={handleApprove} color="primary">
                Salvar
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
