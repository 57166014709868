import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";
import {alpha, makeStyles} from "@material-ui/core/styles";
import CmtImage from "../../../../@coremat/CmtImage";
import Typography from "@material-ui/core/Typography";
import {CurrentAuthMethod} from "../../../constants/AppConstants";
import AuthWrapper from "./AuthWrapper";
import Swal from "sweetalert2";
import InputMask from "react-input-mask";

import CircularProgress from "@material-ui/core/CircularProgress";
import {lighten} from "@material-ui/core/styles";

import {login} from "services/auth";
import {useHistory} from "react-router-dom";

import api from "services/api";

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  },
  circularProgressRoot: {
    left: 0,
    top: 0,
    zIndex: 999999,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "0 auto"
  }
}));
//variant = 'default', 'standard'
const SignIn = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default"
}) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ cpf, setCpf ] = useState("");
  const [ password, setPassword ] = useState("");
  const classes = useStyles({variant});
  const history = useHistory();

  async function handleLogin(e){
    e.preventDefault();
    if (!cpf || !password) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops...",
        text: "Preencher os dados"
      });
    } else {
      setIsLoading(true);
      try {
        let cpfNew = cpf.replace(/[^0-9]/g, "");

        const response = await api.post("auth/login", {cpf: cpfNew, password});
        login(response.data);
        history.push("dashboard");
      } catch (err) {
        Swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          title: "Oops...",
          text: "Autenticação não efetuada!"
        });
        setIsLoading(false);
      }
    }
  }

  return (
    <AuthWrapper variant={wrapperVariant}>
      {/* {variant != 'default' ? ( */}
      <Box className={classes.authThumb}>
        <CmtImage src={"/images/auth/login-img.png"} />
      </Box>
      {/* ) : null} */}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={"/images/logo.png"} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Login
        </Typography>
        {!isLoading ? (
          <form>
            <Box mb={2}>
              <InputMask
                mask="999.999.999-99"
                maskChar=" "
                onChange={event => setCpf(event.target.value)}
                defaultValue={cpf}
              >
                {() => (
                  <TextField
                    label="CPF"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                )}
              </InputMask>
            </Box>
            <Box mb={2}>
              <TextField
                type="password"
                label="Senha"
                fullWidth
                onChange={event => setPassword(event.target.value)}
                defaultValue={password}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Button onClick={handleLogin} variant="contained" color="primary">
                Acessar
              </Button>
            </Box>
          </form>
        ) : (
          <CircularProgress className={classes.circularProgressRoot} />
        )}
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
