import React, {useEffect, useState} from "react";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import {lighten, makeStyles} from "@material-ui/core/styles";

import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import FilterPonto from "./FilterPonto";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  }
}));

const breadcrumbs = [ {label: "Pontos", link: "/", isActive: true} ];

export default function SimpleCard(props){
  const classes = useStyles();
  const [ dataStart, setDataStart ] = useState(
    props.location.state != null
      ? props.location.state.dataInicio
      : moment(new Date()).format("YYYY-MM-DD")
  );
  const [ dataEnd, setDataEnd ] = useState(
    props.location.state != null
      ? props.location.state.dataFim
      : moment(new Date()).format("YYYY-MM-DD")
  );
  const [ userDefault, setUserDefault ] = useState(
    props.location.state != null ? props.location.state.user : "TODOS"
  );

  return (
    <PageContainer heading="Pontos" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{marginBottom: 10}}>Gerenciar Pontos</div>
          <Divider />
          <div style={{marginTop: 24}}>
            <Card className={classes.root}>
              <CardContent>
                <FilterPonto
                  userI={userDefault}
                  dataStart={dataStart}
                  dataEnd={dataEnd}
                />
              </CardContent>
            </Card>
          </div>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
