import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Box, DialogContentText} from "@material-ui/core";
import PageLoader from "@jumbo/components/PageComponents/PageLoader";

import api from "services/api";
import Swal from "sweetalert2";
import {getToken} from "services/auth";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1
  },
  textSave: {
    color: "white"
  }
}));

export default function DeleteEscala(props){
  const classes = useStyles();
  const [ open, setOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const descricao = props.descricao;
  const headers = {Authorization: "Bearer " + getToken()};
  const onChangeItem = props.onChangeItem;

  const handleDelete = () => {
    setIsLoading(true);

    const id = props.id;
    try {
      api
        .delete(`ponto/escalas/${id}`, {headers})
        .then(res => {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            confirmButtonColor: "#3085d6",
            title: "Uhul!",
            text: "Escala excluída."
          });
          handleClose();
          onChangeItem({id: id, nome: descricao});
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos excluir a escala"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(false);
    onChangeItem({id: id, nome: descricao});
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <IconButton
        color="secondary"
        aria-label="Excluir motivo"
        title="Excluir motivo"
        onClick={handleClickOpen}
      >
        <DeleteIcon fontSize="large" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="excluir-motivo"
        fullWidth
      >
        <DialogTitle id="excluir-motivo">
          Excluir Escala
          <DialogContentText>
            Deseja excluir a escala {descricao}?
          </DialogContentText>
        </DialogTitle>
        <DialogActions>
          {!isLoading ? (
            <PageLoader />
          ) : (
            <div>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleDelete} color="secondary">
                Excluir
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
