import React from "react";
import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { getUser } from "services/auth";

const breadcrumbs = [{ label: "Dashboard", link: "/", isActive: true }];

const Dashboard = () => {
  const user = getUser();

  return (
    <PageContainer heading="Dashboard" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{ marginBottom: 10 }}>
            Olá {user.nome}, seja bem vindo!
          </div>
          <Divider />
          <div style={{ marginTop: 24 }} />
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Dashboard;
