import React, {useEffect, useState} from "react";
import api from "services/api";
import {getToken} from "services/auth";
import Swal from "sweetalert2";
import {lighten, makeStyles} from "@material-ui/core/styles";
import moment from "moment";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";

import MenuItem from "@material-ui/core/MenuItem";

import TablePontos from "./TablePontos";
import XlsxPonto from "./XlsxPonto";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    marginBottom: "10px",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  button: {
    "& > *": {
      margin: theme.spacing(2)
    }
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200
  },
  filtro: {
    textAlign: "right"
  },
  text: {
    textAlign: "center",
    color: "rgb(63, 81, 181)"
  }
}));

export default function FilterPonto(props){
  const classes = useStyles();
  const headers = {Authorization: "Bearer " + getToken()};
  const [ dataPonto, setPontoData ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ dataExcel, setDataExcel ] = useState([]);

  const [ dataIni, setDataIni ] = useState(props.dataStart);
  const [ dataFim, setDataFim ] = useState(props.dataEnd);
  const [ userDefault, setUserDefault ] = useState(props.userI);
  const [ users, setUsers ] = useState([]);

  const getAllUsers = async () => {
    try {
      setIsLoading(true);

      api
        .get("ponto/usuarios", {headers})
        .then(res => {
          setUsers(res.data.data);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos consultar o usuário"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: `Não conseguimos conectar na api`
      });
    }
    setIsLoading(true);
  };

  const handleSubmit = event => {
    event.preventDefault();

    let dataInicio = event.target.dataInicio.value;
    let dataFinal = event.target.dataFim.value;
    let user = event.target.elements.user.value;

    if (user === "TODOS") {
      user = null;
    }

    try {
      setIsLoading(true);
      api
        .post(
          "ponto/history",
          {data_ini: dataInicio, data_fim: dataFinal, user_id: user},
          {headers}
        )
        .then(res => {
          setPontoData(res.data.data);
          setDataIni(dataInicio);
          setDataFim(dataFinal);
          setIsLoading(false);
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos consultar os pontos"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(true);

    if (user != null) {
      try {
        api
          .post(
            "ponto/excel",
            {
              data_ini: dataInicio,
              data_fim: dataFinal,
              user_id: user
            },
            {headers}
          )
          .then(response => {
            if (response.data.status == false) {
              Swal.fire({
                icon: "warning",
                confirmButtonColor: "#3085d6",
                title: "Atenção...",
                text: response.data.message
              });
            }
            setDataExcel(response.data.data);
          })
          .catch(err => {
            Swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              title: "Oops...",
              text: "Não conseguimos consultar os pontos"
            });
          });
      } catch (err) {
        Swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          title: "Oops...",
          text: "Não conseguimos conectar na api"
        });
      }
    }
  };

  const getExcel = async () => {
    if (userDefault != "TODOS") {
      try {
        api
          .post(
            "ponto/excel",
            {
              data_ini: dataIni,
              data_fim: dataFim,
              user_id: userDefault
            },
            {headers}
          )
          .then(response => {
            setDataExcel(response.data.data);
          })
          .catch(err => {
            Swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              title: "Oops...",
              text: "Não conseguimos consultar os pontos"
            });
          });
      } catch (err) {
        Swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          title: "Oops...",
          text: "Não conseguimos conectar na api"
        });
      }
    }
  };

  useEffect(() => {
    getAllUsers();
    if (userDefault != "TODOS") {
      getExcel();
    }
    async function handleHistory(){
      try {
        setIsLoading(true);
        await api
          .post(
            "ponto/history",
            {
              data_ini: dataIni,
              data_fim: dataFim,
              user_id: props.userI === "TODOS" ? null : props.userI
            },
            {headers}
          )
          .then(res => {
            setPontoData(res.data.data);
            setIsLoading(false);
          })
          .catch(error => {
            Swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              title: "Oops...",
              text: "Não conseguimos consultar os ponto" + error
            });
          });
      } catch (err) {
        Swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          title: "Oops...",
          text: "Não conseguimos conectar na api"
        });
      }
    }
    setIsLoading(false);
    handleHistory();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Card className={classes.root}>
          <CardContent>
            <Box className={classes.button}>
              <div>
                <TextField
                  id="dateIni"
                  type="date"
                  name="dataInicio"
                  defaultValue={dataIni}
                  className={classes.textField}
                />
                <TextField
                  id="dateFim"
                  type="date"
                  name="dataFim"
                  defaultValue={dataFim}
                  className={classes.textField}
                />
                <TextField
                  select
                  name="user"
                  defaultValue={userDefault}
                  helperText="Por favor selecione o usuário"
                >
                  <MenuItem value={userDefault}>TODOS</MenuItem>
                  {users.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className={classes.filtro}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  endIcon={<SendIcon />}
                >
                  Filtrar
                </Button>
              </div>
            </Box>
          </CardContent>
        </Card>
      </form>
      {!isLoading ? (
        <div>
          <XlsxPonto data={dataExcel} />
          <br />
          {dataPonto ? (
            <div>
              <TablePontos
                dataPonto={dataPonto}
                dataInicio={dataIni}
                dataFim={dataFim}
              />
            </div>
          ) : (
            <p className={classes.text}>Nenhum ponto encontrado</p>
          )}
        </div>
      ) : (
        <PageLoader />
      )}
    </div>
  );
}
