import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Box } from "@material-ui/core";
import {
  lighten,
  makeStyles,
  ThemeProvider,
  createTheme
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { green } from "@material-ui/core/colors";
import InputMask from "react-input-mask";
import Switch from "@material-ui/core/Switch";
import api from "services/api";
import { getToken } from "services/auth";
import Swal from "sweetalert2";
import PageLoader from "@jumbo/components/PageComponents/PageLoader";

const schema = yup
  .object({
    nome: yup.string().required("Nome é obrigatório"),
    cpf: yup.string().required("CPF é obrigatório"),
    role: yup.string().required("Campo obrigatório"),
    escala: yup.string().required(""),
    senha: yup
      .string()
      .required("Senha é obrigatória")
      .min(8, "A senha dever ter no mínimo 8 caracteres"),
    confirmaSenha: yup
      .string()
      .required("Senha é obrigatória")
      .oneOf([yup.ref("senha")], "As senhas não correspondem"),
    email: yup.string().email("Digite um email válido")
  })
  .required();

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  root: {
    margin: "25px 50px",
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
    "& .MuiFormHelperText-root": {
      color: "red"
    }
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200
  },
  textFieldLarge: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: 400,
    "& p": {
      color: "red"
    }
  },
  titleCard: {
    marginTop: "30px",
    marginLeft: "25px"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  formControl: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    minWidth: 120,
    "& p": {
      color: "red"
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(4)
  },
  textSave: {
    color: "white"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  palette: {
    primary: green
  }
});

export default function CreateUser(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const headers = { Authorization: "Bearer " + getToken() };
  const [isLoading, setIsLoading] = useState(false);

  const onChangeItem = props.onChangeItem;
  const dataCentroCusto = props.dataCentroCusto;
  const escalas = props.escalas;
  const dataRoles = props.dataRoles;

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  // Constantes dos campos para criar novo usuario
  const [nome, sendNome] = useState("");
  const [cpf, sendCpf] = useState("");
  const [password, sendPassword] = useState("");
  const [role_id, sendRole_id] = useState("");
  const [matricula, sendMatricula] = useState("");
  const [cargo, sendCargo] = useState("");
  const [rg, sendRG] = useState("");
  const [data_nasc, sendData_nasc] = useState("");
  const [sexo, sendSexo] = useState("");
  const [telefone, sendTelefone] = useState("");
  const [celular, sendCelular] = useState("");
  const [email, sendEmail] = useState("");
  const [descricao, sendDescricao] = useState("");
  const [endereco, sendEndereco] = useState("");
  const [bairro, sendBairro] = useState("");
  const [numero, sendNumero] = useState("");
  const [cep, sendCEP] = useState("");
  const [cidade, sendCidade] = useState("");
  const [estado, sendEstado] = useState("");
  const [data_admissao, sendData_admissao] = useState("");
  const [is_terceiro, sendIs_terceiro] = useState(false);
  const [centrodecustos_id, sendCentroDeCustos_id] = useState("");
  const [id_escala, sendIdEscala] = useState("");
  const [ativo, setAtivo] = useState(true);
  // ========================================== //

  const handleCadastrar = () => {
    handleClose();
    Swal.fire({
      icon: "warning",
      showConfirmButton: false,
      title: "Aguarde... ⏳",
      text: "Estamos adicionando o usuário"
    });
    setIsLoading(true);
    try {
      api
        .post(
          "ponto/usuarios",
          {
            nome: nome,
            cpf: cpf.replace(/[^0-9]/g, ""),
            password: password,
            role: role_id,
            matricula: matricula,
            cargo: cargo,
            rg: rg,
            data_nasc: data_nasc,
            sexo: sexo,
            telefone: telefone.replace(/[^0-9]/g, ""),
            celular: celular.replace(/[^0-9]/g, ""),
            email: email,
            descricao: descricao,
            endereco: endereco,
            bairro: bairro,
            numero: numero,
            cep: cep.replace(/[^0-9]/g, ""),
            cidade: cidade,
            estado: estado,
            data_admissao: data_admissao,
            ativo: "1",
            is_terceiro: is_terceiro,
            id_empresa: "1",
            excluido: false,
            centrodecustos_id: centrodecustos_id,
            id_escala: id_escala,
            ativo: ativo
          },
          { headers }
        )
        .then(res => {
          Swal.fire({
            icon: "success",
            confirmButtonColor: "#3085d6",
            title: "Uhul!",
            text: "Usuário adicionado."
          });
          setIsLoading(false);
          onChangeItem({ nome: nome });
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos adicionar o Usuário" + err
          }).then(function () {
            window.location.reload();
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(false);
    onChangeItem({ nome: nome });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          title="Cadastrar usuário"
        >
          <AddIcon />
        </Button>
      </Box>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {" "}
              Novo Usuário{" "}
            </Typography>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                className={(classes.margin, classes.textSave)}
                onClick={handleSubmit(handleCadastrar)}
              >
                Salvar
              </Button>
            </ThemeProvider>
          </Toolbar>
        </AppBar>

        <DialogContent>
          {isLoading === false ? (
            <div>
              <Card className={classes.root} variant="outlined">
                <h3 className={classes.titleCard}>DADOS PRINCIPAIS</h3>
                <CardContent className={classes.card}>
                  <TextField
                    autoFocus
                    autoComplete={"off"}
                    className={classes.textFieldLarge}
                    type="text"
                    variant="outlined"
                    label="Nome"
                    name="nome"
                    error={errors.nome ? true : false}
                    helperText={errors.nome?.message}
                    {...register("nome", {
                      required: true,
                      onChange: e => sendNome(e.target.value),
                      shouldUnregister: true
                    })}
                  />

                  <InputMask
                    autoComplete={"off"}
                    mask="999.999.999-99"
                    maskChar=" "
                    type="text"
                    label="CPF"
                    name="cpf"
                    variant="outlined"
                    className={classes.textFieldLarge}
                    error={errors.cpf ? true : false}
                    helperText={errors.cpf?.message}
                    {...register("cpf", {
                      required: true,
                      onChange: e => sendCpf(e.target.value)
                    })}
                  >
                    {() => (
                      <TextField
                        autoComplete={"off"}
                        label="CPF"
                        type="text"
                        variant="outlined"
                        className={classes.textFieldLarge}
                        error={errors.cpf ? true : false}
                        helperText={errors.cpf?.message}
                        {...register("cpf", {
                          required: true,
                          onChange: e => sendCpf(e.target.value)
                        })}
                      />
                    )}
                  </InputMask>
                  <TextField
                    autoComplete={"off"}
                    variant="outlined"
                    className={classes.textFieldLarge}
                    type="text"
                    label="RG"
                    name="rg"
                    onChange={e => {
                      sendRG(e.target.value);
                    }}
                  />
                  <TextField
                    id="data_nasc"
                    name="data_nasc"
                    type="date"
                    variant="outlined"
                    label="Data de Nascimento"
                    defaultValue=""
                    className={classes.textField}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => {
                      sendData_nasc(e.target.value);
                    }}
                  />
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Sexo</InputLabel>
                    <Select
                      label="Sexo"
                      defaultValue={""}
                      onChange={e => {
                        sendSexo(e.target.value);
                      }}
                    >
                      <MenuItem value="F">Feminino</MenuItem>
                      <MenuItem value="M">Masculino</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    autoComplete={"off"}
                    variant="outlined"
                    className={classes.formControl}
                    type="text"
                    label="Matricula"
                    name="matricula"
                    onChange={e => {
                      sendMatricula(e.target.value);
                    }}
                  />
                  <TextField
                    id="data_admissao"
                    name="data_admissao"
                    className={classes.textField}
                    type="date"
                    variant="outlined"
                    label="Data de Admissão"
                    defaultValue=""
                    InputLabelProps={{ shrink: true }}
                    onChange={e => {
                      sendData_admissao(e.target.value);
                    }}
                  />
                  <TextField
                    autoComplete={"off"}
                    fullWidth
                    variant="outlined"
                    className={classes.textFieldLarge}
                    type="text"
                    label="Cargo"
                    name="cargo"
                    onChange={e => {
                      sendCargo(e.target.value);
                    }}
                  />
                  <FormControl
                    variant="outlined"
                    className={classes.textFieldLarge}
                  >
                    <InputLabel>Centro de Custos</InputLabel>
                    <Select
                      value={centrodecustos_id}
                      label="Centro de custos"
                      onChange={e => {
                        sendCentroDeCustos_id(e.target.value);
                      }}

                    >
                      {dataCentroCusto.map((centroCusto, index) => (
                        <MenuItem key={index} value={centroCusto.id}>
                          {" "}
                          {centroCusto.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl variant="outlined" className={classes.textField}>
                    <InputLabel>Escala</InputLabel>

                    <Select
                      name="escala"
                      value={id_escala}
                      label="Escalas"
                      error={errors.escala ? true : false}
                      {...register("escala", {
                        required: true,
                        onChange: e => sendIdEscala(e.target.value),
                        shouldUnregister: true
                      })}
                    >
                      {escalas.map((escala, index) => (
                        <MenuItem key={index} value={escala.id}>
                          {" "}
                          {escala.nome}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.escala && (
                      <FormHelperText>Escala é obrigatória</FormHelperText>
                    )}
                  </FormControl>

                  <TextField
                    autoComplete={"off"}
                    className={classes.formControl}
                    variant="outlined"
                    type="password"
                    label="Senha"
                    name="senha"
                    error={errors.senha ? true : false}
                    helperText={errors.senha?.message}
                    {...register("senha", {
                      required: true,
                      onChange: e => sendPassword(e.target.value),
                      shouldUnregister: true

                    })}
                  />

                  <TextField
                    autoComplete={"off"}
                    className={classes.control}
                    variant="outlined"
                    type="password"
                    label="Confirmar Senha"
                    name="confirmaSenha"
                    error={errors.confirmaSenha ? true : false}
                    helperText={errors.confirmaSenha?.message}
                    {...register("confirmaSenha", {
                      required: true,
                      shouldUnregister: true
                    })}
                  />

                  <FormControl
                    variant="outlined"
                    className={classes.textFieldLarge}
                  >
                    <InputLabel>Nível de acesso</InputLabel>
                    <Select
                      name="role"
                      label="Nível de acesso"
                      value={role_id}
                      error={errors.role ? true : false}
                      {...register("role", {
                        required: true,
                        onChange: e => sendRole_id(e.target.value),
                        shouldUnregister: true
                      })}
                    >
                      {dataRoles.map((roles, index) => (
                        <MenuItem key={index} value={roles.id}>
                          {" "}
                          {roles.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    component="fieldset"
                  >
                    <FormLabel component="legend">É Terceiro?</FormLabel>
                    <Switch
                      color="primary"
                      focusVisibleClassName={classes.focusVisible}
                      onChange={e => sendIs_terceiro(e.target.checked)}
                      label="Ativo?"
                      disableRipple
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked
                      }}
                      {...props}
                    />
                  </FormControl>

                  <FormControl
                    component="fieldset"
                  >
                    <FormLabel component="legend">Ativo?</FormLabel>
                    <Switch
                      color="primary"
                      focusVisibleClassName={classes.focusVisible}
                      onChange={e => setAtivo(e.target.checked)}
                      label="Ativo?"
                      disableRipple
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked
                      }}
                      {...props}
                    />
                  </FormControl>
                </CardContent>
              </Card>

              <Card className={classes.root} variant="outlined">
                <h3 className={classes.titleCard}>CONTATO</h3>
                <CardContent>
                  <TextField
                    autoComplete={"off"}
                    fullWidth
                    variant="outlined"
                    className={classes.textFieldLarge}
                    type="text"
                    label="Email"
                    name="email"
                    error={errors.email ? true : false}
                    helperText={errors.email?.message}
                    {...register("email", {
                      onChange: e => sendEmail(e.target.value)
                    })}
                  />

                  <InputMask
                    autoComplete={"off"}
                    className={classes.textFieldLarge}
                    variant="outlined"
                    type="text"
                    mask="(99)9999-9999"
                    label="Telefone"
                    margin="normal"
                    maskChar=" "
                    onChange={e => {
                      sendTelefone(e.target.value);
                    }}
                  >
                    {() => (
                      <TextField
                        autoComplete={"off"}
                        className={classes.textFieldLarge}
                        variant="outlined"
                        type="text"
                        label="Telefone"
                        name="telefone"
                      />
                    )}
                  </InputMask>
                  <InputMask
                    autoComplete={"off"}
                    className={classes.textFieldLarge}
                    variant="outlined"
                    type="text"
                    mask="(99)99999-9999"
                    label="Celular"
                    name="celular"
                    margin="normal"
                    maskChar=" "
                    onChange={e => {
                      sendCelular(e.target.value);
                    }}
                  >
                    {() => (
                      <TextField
                        autoComplete={"off"}
                        className={classes.textFieldLarge}
                        variant="outlined"
                        type="text"
                        label="Celular"
                        name="celular"
                      />
                    )}
                  </InputMask>
                </CardContent>
              </Card>

              <Card className={classes.root} variant="outlined">
                <h3 className={classes.titleCard}>ENDEREÇO</h3>
                <CardContent>
                  <InputMask
                    autoComplete={"off"}
                    className={classes.textFieldLarge}
                    variant="outlined"
                    type="text"
                    mask="99.999-999"
                    label="CEP"
                    name="cep"
                    margin="normal"
                    maskChar=" "
                    onChange={e => {
                      sendCEP(e.target.value);
                    }}
                  >
                    {() => (
                      <TextField
                        autoComplete={"off"}
                        className={classes.textFieldLarge}
                        variant="outlined"
                        type="text"
                        label="CEP"
                        name="cep"
                      />
                    )}
                  </InputMask>

                  <TextField
                    autoComplete={"off"}
                    variant="outlined"
                    className={classes.textFieldLarge}
                    type="text"
                    label="Endereço"
                    name="endereco"
                    onChange={e => {
                      sendEndereco(e.target.value);
                    }}
                  />

                  <TextField
                    autoComplete={"off"}
                    fullWidth
                    variant="outlined"
                    className={classes.textFieldLarge}
                    type="text"
                    label="Bairro"
                    name="bairro"
                    onChange={e => {
                      sendBairro(e.target.value);
                    }}
                  />

                  <TextField
                    autoComplete={"off"}
                    variant="outlined"
                    className={classes.textField}
                    type="text"
                    label="Número"
                    name="numero"
                    onChange={e => {
                      sendNumero(+e.target.value);
                    }}
                  />

                  <TextField
                    autoComplete={"off"}
                    fullWidth
                    variant="outlined"
                    className={classes.textFieldLarge}
                    type="text"
                    label="Cidade"
                    name="cidade"
                    onChange={e => {
                      sendCidade(e.target.value);
                    }}
                  />

                  <TextField
                    autoComplete={"off"}
                    variant="outlined"
                    className={classes.textField}
                    type="text"
                    inputProps={{ maxLength: 2 }}
                    label="Estado"
                    name="estado"
                    onChange={e => {
                      sendEstado(e.target.value);
                    }}
                  />
                </CardContent>
              </Card>

              <Card className={classes.root} variant="outlined">
                <h3 className={classes.titleCard}>DADOS ADICIONAIS</h3>
                <CardContent>
                  <TextField
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 500 }}
                    multiline
                    rows={4}
                    fullWidth
                    label="Descrição"
                    onChange={e => {
                      sendDescricao(e.target.value);
                    }}
                  />
                </CardContent>
              </Card>
            </div>
          ) : (
            <PageLoader />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}