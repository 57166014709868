import React, { useState } from "react";
import { Box, Card, Divider } from "@material-ui/core";
import {
  makeStyles,
  lighten,
  ThemeProvider,
  createTheme
} from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { green } from "@material-ui/core/colors";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";
import api from "services/api";
import { getToken } from "services/auth";
import Swal from "sweetalert2";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

const schema = yup
  .object({
    desc: yup.string().required("Descrição é obrigatório")
  })
  .required();

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginTop: 25,
    overflow: "overlay",

  },
  bancoHoras: {
    display: "flex",
    flexDirection: "column",
    marginTop: "35px",
    marginBottom: "35px"
  },
  tolerancia: {
    marginBottom: "35px"
  },
  rootCard: {
    minWidth: 200,
    maxWidth: 300,
    margin: "0 auto",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  fieldHours: {
    marginTop: 20,
    minWidth: "20ch"
  },
  horaExtra: {
    marginTop: 40,
    padding: 20,
    minWidth: "20ch"
  },
  button: {
    marginTop: 10,
    minWidth: "30ch"
  },
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1
  },
  titleCard: {
    marginTop: "30px",
    marginLeft: "25px"
  },
  textSave: {
    color: "white"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  header: {
    backgroundColor: "#f4f4f7"
  },
  textFieldLarge: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: 400,
    "& p": {
      color: "red"
    }
  },
  boxBancoHoras: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "10px"
  },
  boxTolerancia: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "10px"
  },
  percent: {
    fontWeight: "bold",
    fontSize: "1.1em"
  },
  scroll: {
    overflow: "visible",
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  palette: {
    primary: green
  }
});

export default function CreateEscala(props) {
  const classes = useStyles();
  const dataDias = props.dataDias;
  const headers = { Authorization: "Bearer " + getToken() };

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nome, setNome] = useState("");

  const [bancoHoras, setBancoHoras] = useState(false);
  const [limite, setLimite] = useState(false);
  const [atrasos, setAtrasos] = useState(false);
  const [faltas, setFaltas] = useState(false);
  const [tempoLimite, setTempoLimite] = useState();

  const [tempoMaximo, setTempoMaximo] = useState();
  const [atrasoEntrada, setAtrasoEntrada] = useState();
  const [extraEntrada, setExtraEntrada] = useState();
  const [atrasoIntervalo, setAtrasoIntervalo] = useState();
  const [extraIntervalo, setExtraIntervalo] = useState();
  const [atrasoSaida, setAtrasoSaida] = useState();
  const [extraSaida, setExtraSaida] = useState();

  const [checkId, setCheckId] = useState(true);
  const [disableCampos, setDisableCampos] = useState(true);

  const onChangeItem = props.onChangeItem;

  const handleDiaUtil = (valor, index) => {
    setCheckId(index);
    setDisableCampos(valor);
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleCadastrar = () => {
    try {
      api
        .post(
          "ponto/escalas",
          {
            descricao: nome,
            dataDias,
            banco_horas: bancoHoras,
            debita_hora_atraso: atrasos,
            debita_falta: faltas,
            aplicar_limite: limite,
            tempo_limite_diario: tempoLimite,
            tempo_maximo_tolerancia: tempoMaximo,
            atraso_entrada: atrasoEntrada,
            extra_entrada: extraEntrada,
            atraso_intervalo: atrasoIntervalo,
            extra_intervalo: extraIntervalo,
            atraso_saida: atrasoSaida,
            extra_saida: extraSaida
          },
          { headers }
        )
        .then(res => {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            confirmButtonColor: "#3085d6",
            title: "Uhul!",
            text: "Escala adicionada."
          });
          handleClose();
          onChangeItem({ nome: nome });
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos adicionar a escala"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    handleClose();
    onChangeItem({ nome: nome });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBancoHoras = e => {
    setBancoHoras(e.target.checked);
  };

  const handleLimite = e => {
    setLimite(e.target.checked);
  };

  const handleAtrasos = e => {
    setAtrasos(e.target.checked);
  };

  const handleFaltas = e => {
    setFaltas(e.target.checked);
  };

  return (
    <Box>
      <Box align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          title="Cadastrar escala"
        >
          <AddIcon />
        </Button>
      </Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="escala"
        fullWidth
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {" "}
              Nova Escala{" "}
            </Typography>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                className={(classes.margin, classes.textSave)}
                onClick={handleSubmit(handleCadastrar)}
              >
                Salvar
              </Button>
            </ThemeProvider>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {isLoading !== true ? (
            <div>
              <TextField
                autoFocus
                autoComplete={"off"}
                variant="outlined"
                id="nova_desc"
                label="Descrição Escala"
                type="text"
                className={classes.textFieldLarge}
                name="desc"
                error={errors.desc ? true : false}
                helperText={errors.desc?.message}
                {...register("desc", {
                  required: true,
                  onChange: e => setNome(e.target.value),
                  shouldUnregister: true
                })}
              />
              <Card variant="outlined">
                <h2 className={classes.titleCard}>Dias/Horas</h2>
                <CardContent className={classes.root}>
                  {dataDias.map((dia, index) => (
                    <Card id={index} key={index} sx={{ maxWidth: 345 }} className={classes.scroll}>
                      <CardHeader className={classes.header} title={dia.nome} />
                      <Divider />
                      <CardContent className={classes.rootCard}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={dia.dia_util}
                              onChange={e => {
                                dia["dia_util"] = e.target.checked;
                                handleDiaUtil(e.target.checked, dia.id);
                              }}
                              inputProps={{
                                "aria-label": "controlled"
                              }}
                            />
                          }
                          label="Dia útil?"
                          defaultValue={true}
                        />
                        <TextField
                          id="time"
                          label="Entrada"
                          type="time"
                          disabled={
                            dia.dia_util || disableCampos & (checkId === dia.id)
                              ? false
                              : true
                          }
                          className={classes.fieldHours}
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            step: 300 // 5 min
                          }}
                          onChange={e => {
                            dia["hora_entrada"] = e.target.value;
                          }}
                        />
                        <TextField
                          id="time"
                          label="Início do Intervalo"
                          type="time"
                          disabled={
                            dia.dia_util || disableCampos & (checkId === dia.id)
                              ? false
                              : true
                          }
                          className={classes.fieldHours}
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            step: 300 // 5 min
                          }}
                          onChange={e => {
                            dia["hora_ini_intervalo"] = e.target.value;
                          }}
                        />
                        <TextField
                          id="time"
                          label="Fim do Intervalo"
                          type="time"
                          disabled={
                            dia.dia_util || disableCampos & (checkId === dia.id)
                              ? false
                              : true
                          }
                          className={classes.fieldHours}
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            step: 300 // 5 min
                          }}
                          onChange={e => {
                            dia["hora_fim_intervalo"] = e.target.value;
                          }}
                        />
                        <TextField
                          id="time"
                          label="Saída"
                          type="time"
                          disabled={
                            dia.dia_util || disableCampos & (checkId === dia.id)
                              ? false
                              : true
                          }
                          className={classes.fieldHours}
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            step: 300 // 5 min
                          }}
                          onChange={e => {
                            dia["saida"] = e.target.value;
                          }}
                        />

                        <div className={classes.horaExtra}>
                          <TextField
                            className={classes.fieldHours}
                            type="number"
                            id="input-with-icon-textfield"
                            label="Horas extras"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span className={classes.percent}>%</span>
                                </InputAdornment>
                              )
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              step: 0 // 5 min
                            }}
                            onChange={e => {
                              dia["porcentagem"] = e.target.value;
                            }}
                          />
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </CardContent>
              </Card>

              <Card className={classes.bancoHoras} variant="outlined">
                <h2 className={classes.titleCard}>Banco de Horas</h2>
                <CardContent>
                  <Box className={classes.boxBancoHoras}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          // checked={bancoHoras}
                          onChange={handleBancoHoras}
                          inputProps={{
                            "aria-label": "controlled"
                          }}
                        />
                      }
                      label="Tem banco de horas?"
                    />

                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          // checked={atrasos}
                          onChange={handleAtrasos}
                          disabled={bancoHoras ? false : true}
                          inputProps={{
                            "aria-label": "controlled"
                          }}
                        />
                      }
                      label="Debitar atrasos"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          // checked={faltas}
                          onChange={handleFaltas}
                          disabled={bancoHoras ? false : true}
                          inputProps={{
                            "aria-label": "controlled"
                          }}
                        />
                      }
                      label="Debitar faltas"
                    />

                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          // checked={limite}
                          onChange={handleLimite}
                          inputProps={{
                            "aria-label": "controlled"
                          }}
                        />
                      }
                      label="Aplicar limite?"
                    />

                    <TextField
                      id="tempoLimite"
                      disabled={limite ? false : true}
                      label="Tempo limite diário"
                      type="time"
                      style={{ minWidth: "20ch" }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      onChange={e => setTempoLimite(e.target.value)}
                      defaultValue={tempoLimite}
                    />
                  </Box>
                </CardContent>
              </Card>

              <Card className={classes.tolerancia} variant="outlined">
                <h2 className={classes.titleCard}>Tempos de Tolerância</h2>
                <CardContent>
                  <Box className={classes.boxTolerancia}>
                    <TextField
                      id="tempoMaximo"
                      label="Tempo máximo diário"
                      type="time"
                      style={{ minWidth: "22ch" }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      onChange={e => setTempoMaximo(e.target.value)}
                    />
                    <TextField
                      id="atrasoEntrada"
                      label="Atraso na entrada"
                      type="time"
                      style={{ minWidth: "22ch" }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      onChange={e => setAtrasoEntrada(e.target.value)}
                    />
                    <TextField
                      id="extraEntrada"
                      label="Extra na entrada"
                      type="time"
                      style={{ minWidth: "22ch" }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      onChange={e => setExtraEntrada(e.target.value)}
                    />
                    <TextField
                      id="atrasoIntervalo"
                      label="Atraso no intervalo"
                      type="time"
                      style={{ minWidth: "22ch" }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      onChange={e => setAtrasoIntervalo(e.target.value)}
                    />
                    <TextField
                      id="extraIntervalo"
                      label="Extra no intervalo"
                      type="time"
                      style={{ minWidth: "22ch" }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      onChange={e => setExtraIntervalo(e.target.value)}
                    />
                    <TextField
                      id="atrasoSaida"
                      label="Atraso na saída"
                      type="time"
                      style={{ minWidth: "22ch" }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      onChange={e => setAtrasoSaida(e.target.value)}
                    />
                    <TextField
                      id="extraSaida"
                      label="Extra na saída"
                      type="time"
                      style={{ minWidth: "22ch" }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      onChange={e => setExtraSaida(e.target.value)}
                    />
                  </Box>
                </CardContent>
              </Card>
            </div>
          ) : (
            <PageLoader />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
