import React, {useState, useEffect} from "react";
import {useHistory} from "react-router";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {Box} from "@material-ui/core";
import {lighten, makeStyles, styled} from "@material-ui/core/styles";

import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import Button from "@material-ui/core/Button";
import CmtImage from "../../../@coremat/CmtImage";

import moment from "moment";
import PageLoader from "@jumbo/components/PageComponents/PageLoader";
import {getToken, getUser} from "services/auth";
import Swal from "sweetalert2";
import api from "../../../services/api";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import {MenuItem, MenuList, Paper, Popover} from "@material-ui/core";

import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import XlsxPonto from "./ExcelEspelhoPonto";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  buttonActions: {
    display: "inline-flex",
    alignItems: "flex-end",
    borderRadius: "50px",
    fontWeight: "600",
    lineHeight: "17px",
    fontSize: "14px",
    appearance: "none",
    outline: "none",
    cursor: "pointer",
    padding: "15px 33px",
    border: "2px solid rgb(233, 231, 242)",
    color: "rgb(100, 0, 140)",
    backgroundColor: "white"
  },
  thumbnail: {
    backgroundPosition: "center",
    backgroundSize: "100%",
    borderRadius: "10%",
    height: "100%",
    width: "100%",
    display: "block"
  },
  labels: {
    outline: "none",
    fontSize: "20px",
    lineHeight: "1",
    letterSpacing: "unset",
    color: "rgb(102, 102, 102)"
  },
  ikzcsF: {
    boxShadow: "unset",
    boxSizing: "border-box",
    marginBottom: "20px"
  },
  cbVOcJ: {
    webkitBoxPack: "start",
    justifyContent: "flex-start",
    flexBasis: "44.4444%",
    boxShadow: "unset",
    flexDirection: "column",
    flex: "0 0 100%"
  },
  infos: {
    color: "rgb(51, 51, 51)",
    fontSize: "18px",
    lineHeight: "1",
    letterSpacing: "unset",
    margin: "5px 0px 0px"
  },
  link: {
    color: "rgb(100, 0, 140)",
    textDecoration: "none",
    fontWeight: "600",
    position: "relative",
    display: "inline",
    cursor: "pointer",
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "2px",
      bottom: "-3px",
      transform: "translate(0%,-100%)",
      backgroundColor: "rgb(100, 0, 140)",
      transformOrigin: "left",
      visibility: "hidden",
      transition: "all 0.3s ease-in-out"
    },
    "&:hover:before": {
      visibility: "visible",
      width: "100%"
    }
  },
  title: {
    padding: "10px",
    fontSize: "26px",
    margin: "0px",
    color: "rgb(51, 51, 51)",
    fontWeight: "400",
    textAlign: "left"
  },
  legenda: {
    outline: "none",
    fontSize: "12px",
    lineHeight: "1",
    letterSpacing: "unset",
    color: "rgb(102, 102, 102)",
    paddingBottom: "10px"
  },
  horas_legenda: {
    color: "rgb(51, 51, 51)",
    fontSize: "15px",
    lineHeight: "1",
    letterSpacing: "unset",
    borderBottom: "2px solid rgb(35, 198, 175)"
  },
  table: {
    minWidth: 650
  },
  totalHoras: {
    position: "relative",
    width: "auto",
    marginRight: "40px",
    backgroundColor: "white",
    borderRadius: "20px",
    boxShadow: "rgb(0 0 0 / 6%) 0px 16px 20px 0px",
    padding: "22px 2px 2px 21px",
    marginTop: "0px",
    marginBottom: "-20px"
  },
  diaSemana: {
    fontSize: "11px",
    lineHeight: "normal",
    letterSpacing: "unset",
    color: "rgb(102, 102, 102)",
    wordBreak: "break-all"
  },
  popover: {
    appearance: "none",
    outline: "none",
    cursor: "pointer",
    color: "rgb(100, 0, 140)"
  }
}));

const breadcrumbs = [ {label: "Espelho Ponto", link: "/", isActive: true} ];

export default function DataEspelhoPonto(props){
  const classes = useStyles();
  const headers = {Authorization: "Bearer " + getToken()};
  const userLogado = getUser();
  const history = useHistory();

  const [ dataInicio, setDataInicio ] = useState(
    props.location.state != null
      ? props.location.state.dataInicio
      : moment(new Date()).format("YYYY-MM-DD")
  );
  const [ dataFim, setDataFim ] = useState(
    props.location.state != null
      ? props.location.state.dataFim
      : moment(new Date()).format("YYYY-MM-DD")
  );
  const [ idUser, setIdUser ] = useState(
    props.location.state != null ? props.location.state.user : null
  );

  const [ statusEspelhoPonto, setStatusEspelhoPonto ] = useState(
    props.location.state != null
      ? props.location.state.statusEspelhoPonto
      : null
  );
  const idEspelhoPonto =
    props.location.state != null ? props.location.state.idEspelhoPonto : null;

  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const [ user, setUser ] = useState([]);
  const [ funcionario, setFuncionario ] = useState([]);
  const [ dscEscala, setDscEscala ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ dataPontos, setDataPontos ] = useState([]);
  const [
    dataHorasTrabalhasAgrupadas,
    setDataHorasTrabalhasAgrupadas
  ] = useState([]);
  const [ dataMarcacoesAgrupadas, setDataMarcacoesAgrupadas ] = useState([]);

  const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary
  }));

  useEffect(() => {
    getUserEspelhoPonto();
    getDataPontos();
  }, []);

  const getUserEspelhoPonto = async () => {
    try {
      setIsLoading(true);
      api
        .get(`ponto/usuarios/` + idUser, {headers}, {password: true})
        .then(response => {
          setFuncionario(response.data.data.func);
          setDscEscala(response.data.data.func.dscEscala.nome);
          setUser(response.data.data);
          setIsLoading(false);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos consultar o usuário"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(true);
  };

  const getDataPontos = async () => {
    try {
      setIsLoading(true);

      api
        .post(
          "ponto/excel",
          {
            data_ini: dataInicio,
            data_fim: dataFim,
            user_id: idUser
          },
          {headers}
        )
        .then(response => {
          if (response.data.status == false) {
            Swal.fire({
              icon: "warning",
              confirmButtonColor: "#3085d6",
              title: "Atenção...",
              text: response.data.message
            }).then(result => {
              if (result.isConfirmed) {
                history.push("/espelho-ponto");
              }
            });
            setIsLoading(false);
          } else {
            setDataPontos(response.data.data);
            setDataHorasTrabalhasAgrupadas(
              response.data.data["somaHorasExtras"]
            );
            setDataMarcacoesAgrupadas(response.data.data["data"]);
          }
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos consultar os pontos"
          }).then(result => {
            if (result.isConfirmed) {
              history.push("/espelho-ponto");
            }
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      }).then(result => {
        history.push("/espelho-ponto");
      });
    }
  };

  function handleFecharEspelhoPonto(){
    if (statusEspelhoPonto === "FECHADO" || statusEspelhoPonto === "F") {
      Swal.fire({
        icon: "warning",
        confirmButtonColor: "#3085d6",
        title: "Opss... &#128517;",
        text: "Esse Espelho Ponto já esta fechado."
      });
      return;
    }

    try {
      setIsLoading(true);
      api
        .post(
          `ponto/espelho-ponto`,
          {
            dataInicio: dataInicio,
            dataFim: dataFim,
            userFuncionario: idUser,
            userResponsavel: userLogado.id
          },
          {headers}
        )
        .then(response => {
          setStatusEspelhoPonto(response.data.data.dscStatus);
          setDataPontos(response.data.data);
          getDataPontos();
          Swal.fire({
            icon: "success",
            confirmButtonColor: "#3085d6",
            title: "Uhul... 👍",
            text: "Espelho Ponto fechado."
          });
          setIsLoading(false);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops... 😞",
            text: "Não conseguimos fechar esse espelho ponto" + error
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops... 😖",
        text: "Não conseguimos conectar na api"
      });
    }

    setIsLoading(false);
  }

  const handleUp = () => {
    if (statusEspelhoPonto === "ABERTO" || statusEspelhoPonto === "A") {
      Swal.fire({
        icon: "warning",
        confirmButtonColor: "#3085d6",
        title: "Opss... &#128517;",
        text: "Esse espelho Ponto já esta aberto."
      });
      return;
    }
    try {
      setIsLoading(true);
      api
        .patch(
          `ponto/espelho-ponto/reopen/${idEspelhoPonto}`,
          {userLogado: userLogado.id},
          {headers}
        )
        .then(res => {
          setIsLoading(false);
          setStatusEspelhoPonto(res.data.data.dscStatus);
          setDataPontos(res.data.data);
          getDataPontos();
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            confirmButtonColor: "#3085d6",
            title: "Uhul...👍",
            text: "Espelho ponto aberto."
          });
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops... 😞",
            text: "Não poi possível reabrir o espelho ponto"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...😖",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(false);
  };

  function onChangeItem(item){
    handlePopoverOpen(item.onChangeItem);
  }

  return (
    <PageContainer heading="Espelho Ponto" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{display: "flex"}} />
          <div style={{marginBottom: 10}}>
            Gerenciar Espelho Ponto de {user.nome} {" "}
            {statusEspelhoPonto == "ABERTO" || statusEspelhoPonto == "A" ? (
              <Chip
                label={statusEspelhoPonto}
                style={{backgroundColor: "orange", height: "20px"}}
              />
            ) : (
              <Chip
                label={statusEspelhoPonto}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  height: "20px"
                }}
              />
            )}
          </div>
          <Divider />
          {isLoading != true ? (
            <div style={{marginTop: 24}}>
              <Box sx={{flexGrow: 1}}>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <CmtImage
                      src={"/images/employee-thumbnail.png"}
                      className={classes.thumbnail}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <div>
                      <p className={classes.labels} style={{marginTop: "8px"}}>
                        Espelho ponto de {" "}
                      </p>
                      <h2 className={classes.title}>{user.nome} </h2>
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#64008C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <rect
                            x="3"
                            y="4"
                            width="18"
                            height="18"
                            rx="2"
                            ry="2"
                          />
                          <line x1="16" y1="2" x2="16" y2="6" />
                          <line x1="8" y1="2" x2="8" y2="6" />
                          <line x1="3" y1="10" x2="21" y2="10" />
                        </svg>{" "}
                        No período de {moment(dataInicio).format(
                          "DD/MM/YYYY"
                        )}{" "}
                        a {moment(dataFim).format("DD/MM/YYYY")}{" "}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className={classes.buttonActions}
                      onClick={handleFecharEspelhoPonto}
                    >
                      Fechar Espelho Ponto
                    </Button>
                    <Button
                      className={classes.buttonActions}
                      onClick={handlePopoverOpen}
                    >
                      + Ações
                    </Button>
                    {open && (
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        container={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right"
                        }}
                        transformOrigin={{
                          vertical: "center",
                          horizontal: "left"
                        }}
                      >
                        <Paper elevation={8} className={classes.popover}>
                          <MenuList>
                            <MenuItem onClick={handlePopoverOpen}>
                              <PictureAsPdfIcon />
                              <a
                                href={
                                  `${window.location
                                    .origin}/api/public/api/ponto/generate-pdf/` +
                                  idUser +
                                  "/" +
                                  dataInicio +
                                  "/" +
                                  dataFim +
                                  "/S"
                                }
                              >
                                <div className="ml-2">Pŕevia Espelho Ponto</div>
                              </a>
                            </MenuItem>
                            <MenuItem onClick={handlePopoverOpen}>
                              <DownloadIcon />
                              <a
                                href={
                                  `${window.location
                                    .origin}/api/public/api/ponto/generate-pdf/` +
                                  idUser +
                                  "/" +
                                  dataInicio +
                                  "/" +
                                  dataFim +
                                  "/N"
                                }
                              >
                                <div className="ml-2">Espelho Ponto - PDF</div>
                              </a>
                            </MenuItem>
                            <XlsxPonto
                              data={dataPontos}
                              onChangeItem={onChangeItem}
                            />
                          </MenuList>
                          <MenuItem onClick={handlePopoverOpen}>
                            <LockOpenIcon />
                            <a onClick={handleUp}>
                              <div className="ml-2">Reabrir Espelho Ponto</div>
                            </a>
                          </MenuItem>
                        </Paper>
                      </Popover>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <br />
              <Box sx={{flexGrow: 1}}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className={classes.totalHoras}>
                      <div className={classes.cbVOcJ}>
                        <div className={classes.ikzcsF}>
                          <label className={classes.labels}>
                            Funcionário(a)
                          </label>
                          <p className={classes.infos}>
                            <a className={classes.link} href="#">
                              <span>{user.nome}</span>
                            </a>
                          </p>
                        </div>
                        <div className={classes.ikzcsF}>
                          <label className={classes.labels}>CPF</label>
                          <p className={classes.infos}>{user.cpf}</p>
                        </div>
                        <div className={classes.ikzcsF}>
                          <label className={classes.labels}>
                            Escala de Trabalho
                          </label>
                          <p className={classes.infos}>
                            <a className={classes.link} href="#">
                              <span>{dscEscala}</span>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.totalHoras}>
                      <div>
                        <h3 style={{marginBottom: "15px"}}>
                          Resumo das horas trabalhadas no período
                        </h3>
                        {dataHorasTrabalhasAgrupadas.map(row => {
                          let retorno = row.split(";");
                          let horas = retorno[1].split(":");
                          return (
                            <div className={classes.ikzcsF}>
                              <div className={classes.labels}>
                                <label>
                                  {retorno[0]} :{" "}
                                  <span style={{color: "#000"}}>
                                    {horas[0]}h{horas[1]}min
                                  </span>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} />
                </Grid>
              </Box>
              <br />
              <br />
              <br />
              <br />
              <Box sx={{flexGrow: 1}}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {dataMarcacoesAgrupadas ? (
                      <TableContainer
                        component={Paper}
                        className={classes.root}
                      >
                        <Table
                          className={classes.table}
                          aria-label="espelho-ponto"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell component="th">Data</TableCell>
                              <TableCell component="th">Registro</TableCell>
                              <TableCell component="th">Observações</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dataMarcacoesAgrupadas.map((marcacao, id) => {
                              return (
                                <TableRow key={id}>
                                  <TableCell component="td" scope="row">
                                    <p>{marcacao.data}</p>
                                    <p className={classes.diaSemana}>
                                      {marcacao.dia_semana}
                                    </p>
                                  </TableCell>
                                  <TableCell component="td" scope="row">
                                    {marcacao.falta ==
                                    " " ? marcacao.horas_trabalhadas !=
                                    " " ? marcacao.horas_extras != " " ? (
                                      "Hora Extra"
                                    ) : (
                                      "OK"
                                    ) : (
                                      "Descanso Compensado"
                                    ) : (
                                      "Falta Injustificada"
                                    )}{" "}
                                    {marcacao.marcacoesDia}
                                  </TableCell>
                                  <TableCell component="td" scope="row">
                                    {marcacao.falta ==
                                    " " ? marcacao.horas_trabalhadas !=
                                    " " ? marcacao.horas_extras != " " ? (
                                      "Hora Extra: " + marcacao.horas_extras
                                    ) : (
                                      "Horário Normal: " +
                                      marcacao.horas_trabalhadas
                                    ) : (
                                      "Descanso Compensado"
                                    ) : (
                                      "Falta: " + marcacao.falta
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <p>Nenhum ponto encontrado </p>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </div>
          ) : (
            <PageLoader />
          )}
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
