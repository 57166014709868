import React from "react";
import {Router} from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AppWrapper from "./@jumbo/components/AppWrapper";
import AppContextProvider from "./@jumbo/components/contextProvider/AppContextProvider";
import Routes from "./routes";
import history from "./history";

const App = () => (
  <Router history={history}>
    <AppContextProvider>
      <AppWrapper>
        <Routes />
      </AppWrapper>
    </AppContextProvider>
  </Router>
);

export default App;
