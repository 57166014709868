import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Box} from "@material-ui/core";

import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import moment from "moment";

export default function FormDialog(props){
  const [ open, setOpen ] = useState(false);
  const status = useState(props.status);
  const dataHora = useState(moment(props.data).format("DD/MM/YYYY HH:mm"));
  const data = useState(moment(props.data).format("HH:mm"));
  const obsFunc = useState(props.obsFunc);
  const nomeFunc = useState(props.nomeFunc);
  const motivo = useState(props.motivo);
  const obsSuper = useState(props.obsSuper);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Chip
        size="small"
        label={data}
        clickable
        color={status[0] === "PENDENTE" ? "secondary" : "primary"}
        deleteIcon={<DoneIcon />}
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle id="simple-dialog-title">
          Visualização Ajuste de Ponto
        </DialogTitle>
        <DialogContent>
          <div>
            <strong>Funcionário: </strong>
            {nomeFunc}
          </div>
          <div>
            <strong>Data/Hora: </strong>
            {dataHora}
          </div>
          <div>
            <p>
              <strong>Situação: </strong> {status}
            </p>
          </div>
          <div>
            <p>
              <strong>Motivo: </strong> {status}
            </p>
          </div>
          <div>
            <p>
              <strong>Observação Funcionário: </strong>
              {motivo}
            </p>
          </div>
          <div>
            <p>
              <strong>Observação Supervisor: </strong>
              {obsSuper}
            </p>
          </div>
        </DialogContent>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        />
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
