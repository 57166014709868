import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PageLoader from "@jumbo/components/PageComponents/PageLoader";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import api from "services/api";
import { getToken } from "services/auth";
import Swal from "sweetalert2";

const schema = yup
  .object({
    motivo: yup.string().required("Motivo é obrigatório")
  })
  .required();

export default function CreateMotivo(props) {
  const [open, setOpen] = React.useState(false);
  const [motivo, sendMotivo] = useState();
  const headers = { Authorization: "Bearer " + getToken() };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const [isLoading, setIsLoading] = useState(false);
  const onChangeItem = props.onChangeItem;

  const handleCadastrar = () => {
    setIsLoading(true);
    try {
      api
        .post("ponto/motivos", { descricao: motivo, ativo: "1" }, { headers })
        .then(res => {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            confirmButtonColor: "#3085d6",
            title: "Uhul!",
            text: "Motivo adicionado."
          });
          handleClose();
          onChangeItem({ nome: motivo });
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos adicionar o motivo"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
      setIsLoading(false);
      onChangeItem({ nome: motivo });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          title="Cadastrar motivo"
        >
          <AddIcon />
        </Button>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="motivo"
        fullWidth
      >
        <DialogTitle id="motivo">Cadastrar Motivo</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            id="novo_motivo"
            label="Descrição"
            type="text"
            name="motivo"
            error={errors.motivo ? true : false}
            helperText={errors.motivo?.message}
            {...register("motivo", {
              required: true,
              onChange: e => sendMotivo(e.target.value),
              shouldUnregister: true
            })}
          />
        </DialogContent>
        <DialogActions>
          {isLoading == true ? (
            <PageLoader />
          ) : (
            <div>
              <Button onClick={handleClose} color="secondary">
                Cancelar
              </Button>
              <Button onClick={handleSubmit(handleCadastrar)} color="primary">
                Salvar
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
