import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import {lighten, makeStyles} from "@material-ui/core/styles";

import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";
import {getToken} from "../../../services/auth";
import Swal from "sweetalert2";
import api from "../../../services/api";

import FilterEspelhoPonto from "../EspelhoPonto/FilterEspelhoPonto";
import TableEspelhoPonto from "../EspelhoPonto/TableEspelhoPontos";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  }
}));

const breadcrumbs = [ {label: "Espelho Ponto", link: "/", isActive: true} ];

export default function SimpleCard(){
  const classes = useStyles();
  const headers = {Authorization: "Bearer " + getToken()};
  const [ isLoading, setIsLoading ] = useState(true);

  const [ users, setUsers ] = useState([]);
  const [ userFuncionario, setUserFuncionario ] = useState(null);

  let date = new Date();
  const [ dataIni, setDataIni ] = useState(
    moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      "YYYY-MM-DD"
    )
  );
  const [ dataFim, setDataFim ] = useState(
    moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
      "YYYY-MM-DD"
    )
  );

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async (dataIni, dataFim, userFuncionario) => {
    try {
      setIsLoading(true);
      api
        .post(
          "ponto/espelho-ponto/usuarios",
          {
            dataInicio: dataIni,
            dataFim: dataFim,
            userFuncionario: userFuncionario
          },
          {headers}
        )
        .then(response => {
          setUsers(response.data.data);
          setIsLoading(false);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085D6",
            title: "Oops...",
            text: "Não conseguimos consultar os usuarios" + error
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(true);
  };

  function onChangeItem(item){
    setDataIni(item.dataIni);
    setDataFim(item.dataFim);
    setUserFuncionario(item.userFuncionario);
    getAllUsers(item.dataIni, item.dataFim, item.userFuncionario);
  }

  return (
    <PageContainer heading="Espelho Ponto" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{marginBottom: 10}}>Gerenciar Espelho Ponto</div>
          <Divider />
          <div style={{marginTop: 24}}>
            <Card className={classes.root}>
              <CardContent>
                <FilterEspelhoPonto
                  onChangeItem={onChangeItem}
                  dataInicio={dataIni}
                  dataFim={dataFim}
                />
                <br />
                {isLoading != true ? (
                  <TableEspelhoPonto
                    users={users}
                    dataInicio={dataIni}
                    dataFim={dataFim}
                  />
                ) : (
                  <PageLoader />
                )}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
