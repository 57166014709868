export const TOKEN_KEY = "@ponto-token";
export const USER = "user";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUser = () => JSON.parse(localStorage.getItem(USER));

export const login = data => {
  localStorage.setItem(TOKEN_KEY, " ");
  localStorage.setItem(TOKEN_KEY, data.token);
  localStorage.setItem(USER, JSON.stringify(data.data));
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER);
};
