import React, {useState} from "react";
import {Slide, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import PageLoader from "../PageComponents/PageLoader";

function SlideTransition(props){
  return <Slide {...props} direction="left" />;
}

const ContentLoader = () => {
  const {error, loading, message} = useState();

  return (
    <React.Fragment>
      {loading && <PageLoader />}
      {
        <Snackbar
          open={Boolean(error)}
          anchorOrigin={{vertical: "top", horizontal: "right"}}
          TransitionComponent={SlideTransition}
        >
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        </Snackbar>
      }
      {
        <Snackbar
          open={Boolean(message)}
          anchorOrigin={{vertical: "top", horizontal: "right"}}
          TransitionComponent={SlideTransition}
        >
          <Alert variant="filled" severity="success">
            {message}
          </Alert>
        </Snackbar>
      }
    </React.Fragment>
  );
};

export default ContentLoader;
