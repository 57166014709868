import React, { useState } from "react";

import { Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { getToken } from "../../../services/auth";
import Swal from "sweetalert2";
import api from "../../../services/api";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";

const schema = yup
  .object({
    centroCustos: yup.string().required("Centro de Custos é obrigatório")
  })
  .required();

export default function UpdateCentroCustos(props) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [centroCustos, setCentroCustos] = useState();
  const headers = { Authorization: "Bearer " + getToken() };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onChangeItem = props.onChangeItem;
  const id = props.id;
  const nome = props.nome;

  const updateCentroCustos = () => {
    setIsLoading(true);
    try {
      api
        .patch(
          `ponto/centro-custos/${id}`,
          { id: id, nome: centroCustos, ativo: 1 },
          { headers }
        )
        .then(res => {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            confirmButtonColor: "#3085D6",
            title: "Uhul!",
            text: "Centro de Custos Atualizado."
          });
          handleClose();
          onChangeItem({ id: id, nome: nome });
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085D6",
            title: "Oops...",
            text: "Não poi possível atualizar o Centro de Custos"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(false);
    onChangeItem({ id: id, nome: nome });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box>
        <IconButton
          color="primary"
          aria-label="Editar Centro de Custos"
          title="Editar Centro de Custos"
          onClick={handleClickOpen}
        >
          <EditIcon fontSize="large" />
        </IconButton>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="editar-centro-custos"
        fullWidth
      >
        <DialogTitle id="editar-centro-custos">
          Editar Centro de Custos
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            id="nome_centro_custos"
            label="Descrição"
            type="text"
            defaultValue={nome}
            name="centroCustos"
            error={errors.centroCustos ? true : false}
            helperText={errors.centroCustos?.message}
            {...register("centroCustos", {
              required: true,
              onChange: e => setCentroCustos(e.target.value)
            })}
          />
        </DialogContent>

        <DialogActions>
          {isLoading == true ? (
            <PageLoader />
          ) : (
            <div>
              <Button onClick={handleClose} color="secondary">
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSubmit(updateCentroCustos)}
              >
                Editar
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
