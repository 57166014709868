import React from "react";

const LanguageSwitcher = () => {
  return (
    <React.Fragment>
      {/* <IconButton size="small" onClick={handleClick} data-tut="reactour__localization">
        <CmtImage src={flags[locale.locale]} />
      </IconButton>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Language"
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
          <PerfectScrollbar className={classes.perfectScrollbarLanguage}>
            <CmtList
              data={languageData}
              renderRow={(item, index) => <LanguageItem key={index} language={item} onClick={switchLanguage} />}
            />
          </PerfectScrollbar>
        </CmtCard>
      </Popover> */}
    </React.Fragment>
  );
};

export default LanguageSwitcher;
