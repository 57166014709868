import React from "react";
import {PostAdd} from "@material-ui/icons";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import {
  Group,
  ViewList,
  Adjust,
  LocationSearching,
  MonetizationOn
} from "@material-ui/icons";

export const sidebarNavs = [
  {
    name: "Menu",
    type: "section",
    children: [
      {
        name: "Dashboard",
        type: "item",
        icon: <PostAdd />,
        link: "/dashboard"
      },
      {
        name: "Usuários/Func.",
        icon: <Group />,
        type: "item",
        link: "/users"
      },
      {
        name: "Consulta Ponto",
        icon: <LocationSearching />,
        type: "item",
        link: "/pontos"
      },
      {
        name: "Ajustes",
        icon: <Adjust />,
        type: "item",
        link: "/ajustes-pontos"
      },
      {
        name: "Espelho Ponto",
        type: "item",
        icon: <PostAdd />,
        link: "/espelho-ponto"
      },
      {
        name: "Motivos",
        icon: <ViewList />,
        type: "item",
        link: "/motivos"
      },
      {
        name: "Centro de Custos",
        icon: <MonetizationOn />,
        type: "item",
        link: "/centrocustos"
      },
      {
        name: "Escala",
        icon: <WatchLaterIcon />,
        type: "item",
        link: "/escalas"
      }
    ]
  }
];

export const sidebarNavsC = [
  {
    name: "Menu",
    type: "section",
    children: [
      {
        name: "Dashboard",
        type: "item",
        icon: <PostAdd />,
        link: "/dashboard"
      }
    ]
  }
];

export const horizontalDefaultNavs = [
  {
    name: "sidebar.main",
    type: "collapse",
    children: [
      {
        name: "pages.samplePage",
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page"
      }
    ]
  }
];

export const minimalHorizontalMenus = [
  {
    name: "sidebar.main",
    type: "collapse",
    children: [
      {
        name: "pages.samplePage",
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page"
      }
    ]
  }
];
