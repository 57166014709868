import React from "react";
import {lighten, makeStyles} from "@material-ui/core/styles";
import moment from "moment";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import {Box} from "@material-ui/core";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ModalAjustePonto from "./ModalAjustesPonto";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    marginBottom: "10px",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  button: {
    "& > *": {
      margin: theme.spacing(2)
    }
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200
  },
  ul: {
    listStyleType: "none",
    display: "flex",
    paddingRight: "14px"
  },
  li: {
    listStyleType: "none",
    display: "flex",
    paddingRight: "14px"
  },
  lista: {
    listStyleType: "none",
    display: "flex",
    paddingRight: "14px"
  },
  accordion: {
    backgroundColor: "#f4f4f7",
    margin: theme.spacing(2)
  },
  accordionDetails: {
    display: "block"
  },
  buttonEspelhoPonto: {
    marginLeft: "1000px;"
  }
}));

export default function TablePonto({dataPonto, dataInicio, dataFim}){
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {Object.values(dataPonto).map(user => (
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={user.id}
            id={user.id}
          >
            <Typography className={classes.heading}>{user.name}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {/* <Typography>
              <Link
                to={{
                  pathname: "espelho-ponto/perfil",
                  state: {
                    user: user.id,
                    dataInicio: dataInicio,
                    dataFim: dataFim,
                    statusEspelhoPonto: user.statusEspelhoPonto
                  }
                }}
              > <span>Ver espelho ponto</span></Link>
            </Typography>
            <br /> */}
            <Typography>
              {Object.values(user).map(data => (
                <Card className={classes.root}>
                  {typeof data === "object" && (
                    <CardContent>
                      <Typography gutterBottom className={classes.heading}>
                        {data.data}
                      </Typography>
                      <Box className={classes.button}>
                        <ul className={classes.ul}>
                          {Object.values(data).map((ponto, index) => (
                            <li className={classes.li} key={index}>
                              {typeof ponto === "object" &&
                              ponto.is_ajuste === false ? (
                                moment(ponto.data).format("HH:mm")
                              ) : (
                                typeof ponto === "object" &&
                                ponto.is_ajuste === true && (
                                  <ModalAjustePonto
                                    status={ponto.dscStatus}
                                    data={ponto.data}
                                    obsFunc={ponto.obs_funcionario}
                                    obsSuper={ponto.obs_supervisor}
                                    nomeFunc={ponto.dscUser.nome}
                                  />
                                )
                              )}
                            </li>
                          ))}
                        </ul>
                      </Box>
                    </CardContent>
                  )}
                </Card>
              ))}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
