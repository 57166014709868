import React from "react";

const HeaderMessages = () => {
  return (
    <div>
      {/* <Tooltip title="Messages">
        <IconButton onClick={onOpenPopOver} className={clsx(classes.iconRoot, 'Cmt-appIcon')}>
          <MessageIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Messages"
            actionsPos="top-corner"
            actions={actions}
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
          <CmtCardContent>
            {messages.length > 0 ? (
              <PerfectScrollbar className={classes.scrollbarRoot}>
                <CmtList data={messages} renderRow={(item, index) => <MessageItem key={index} item={item} />} />
              </PerfectScrollbar>
            ) : (
              <Box p={6}>
                <Typography variant="body2">No messages found</Typography>
              </Box>
            )}
          </CmtCardContent>
        </CmtCard>
      </Popover> */}
    </div>
  );
};

export default HeaderMessages;
