import React from "react";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteMotivo from "./DeleteMotivo";
import UpdateMotivo from "./UpdateMotivo";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 30,
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  table: {
    minWidth: 650
  },
  check: {
    color: "green"
  },
  close: {
    color: "red"
  }
}));

export default function TableMotivo(props){
  const classes = useStyles();
  const dataMotivo = props.dataMotivo;
  const onChangeItem = props.onChangeItem;

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="motivo">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell align="left">Ativo</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataMotivo.map((motivo, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {motivo.descricao}
              </TableCell>
              <TableCell component="th" scope="row">
                {motivo.ativo ? (
                  <CheckIcon className={classes.check} />
                ) : (
                  <CloseIcon className={classes.close} />
                )}
              </TableCell>
              <TableCell align="left">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  <UpdateMotivo
                    id={motivo.id}
                    nome={motivo.descricao}
                    onChangeItem={onChangeItem}
                  />
                  <DeleteMotivo
                    id={motivo.id}
                    descricao={motivo.descricao}
                    onChangeItem={onChangeItem}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
