import React, {useEffect, useState} from "react";
import api from "services/api";
import {getToken, getUser} from "services/auth";
import Swal from "sweetalert2";
import {lighten, makeStyles} from "@material-ui/core/styles";
import moment from "moment";

import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";

import {MenuItem} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    marginBottom: "10px",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  button: {
    "& > *": {
      margin: theme.spacing(2)
    }
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200
  },
  filtro: {
    textAlign: "right"
  },
  text: {
    textAlign: "center",
    color: "rgb(63, 81, 181)"
  },
  groupButton: {
    marginRight: "50px"
  },
  buttonActions: {
    display: "inline-flex",
    alignItems: "flex-end",
    borderRadius: "50px",
    fontWeight: "600",
    lineHeight: "17px",
    fontSize: "14px",
    appearance: "none",
    outline: "none",
    cursor: "pointer",
    padding: "15px 33px",
    border: "2px solid rgb(233, 231, 242)",
    color: "rgb(100, 0, 140)",
    backgroundColor: "white"
  }
}));

export default function FilterEspelhoPonto(props){
  const classes = useStyles();
  const headers = {Authorization: "Bearer " + getToken()};
  const onChangeItem = props.onChangeItem;
  const [ isLoading, setIsLoading ] = useState(true);

  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const [ dataIni, setDataIni ] = useState(props.dataInicio);
  const [ dataFim, setDataFim ] = useState(props.dataFim);

  const [ userDefault, setUserDefault ] = useState("TODOS");

  const [ dataMeses, setDataMeses ] = useState([]);
  const [ users, setUsers ] = useState([]);

  useEffect(() => {
    getMonths();
    getAllUsers();
  }, []);

  const getMonths = async () => {
    try {
      api
        .get("ponto/espelho-ponto/mesesDoAno", {headers})
        .then(response => {
          setDataMeses(response.data.data);
          setIsLoading(false);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085D6",
            title: "Oops...",
            text: "Não conseguimos consultar os meses" + error
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(true);
  };

  const getAllUsers = async () => {
    try {
      api
        .get("ponto/usuarios", {headers})
        .then(res => {
          setUsers(res.data.data);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085D6",
            title: "Oops...",
            text: "Não conseguimos consultar os usuários "
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: `Não conseguimos conectar na api`
      });
    }
    setIsLoading(true);
  };

  const handleSubmit = event => {
    event.preventDefault();
    let periodo = event.target.periodo.value.split("/");
    let userFuncionario = event.target.elements.user.value;

    setDataIni(periodo[0]);
    setDataFim(periodo[1]);
    if (userFuncionario === "TODOS") {
      userFuncionario = null;
    }

    onChangeItem({
      dataIni: periodo[0],
      dataFim: periodo[1],
      userFuncionario: userFuncionario
    });
  };

  function FecharTodosEspelhosPontos(){
    Swal.fire({
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, fechar",
      cancelButtonText: "Cancelar",
      title: "Atenção...",
      text:
        "Você tem certeza que deseja fechar todos os espelhos pontos dos funcionários listados no período de " +
        moment(dataIni).format("DD/MM/YYYY") +
        " à " +
        moment(dataFim).format("DD/MM/YYYY") +
        "?"
    }).then(result => {
      if (result.isConfirmed) {
        let usuarioLogado = getUser();
        try {
          setIsLoading(true);
          api
            .post(
              `ponto/espelho-ponto/close-all`,
              {
                dataInicio: dataIni,
                dataFim: dataFim,
                usuarios: users,
                userResponsavel: usuarioLogado.id
              },
              {headers}
            )
            .then(response => {
              setIsLoading(false);
              onChangeItem({
                dataIni: dataIni,
                dataFim: dataFim
              });
              Swal.fire({
                icon: "success",
                confirmButtonColor: "#3085d6",
                title: "Uhul... 👍",
                text: "Espelho Ponto fechado."
              });
            })
            .catch(error => {
              Swal.fire({
                icon: "error",
                confirmButtonColor: "#3085d6",
                title: "Oops... 😞",
                text: "Não conseguimos fechar esse espelho ponto" + error
              });
            });
        } catch (err) {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops... 😖",
            text: "Não conseguimos conectar na api"
          });
        }
        setIsLoading(true);
      }
    });
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box className={classes.button}>
          <div>
            <TextField
              style={{marginRight: "50px"}}
              select
              defaultValue={dataIni + "/" + dataFim}
              name="periodo"
              helperText="Por favor selecione um período"
            >
              <MenuItem value={dataIni + "/" + dataFim}>
                {moment(dataIni).format("DD/MM/YYYY")} A{" "}
                {moment(dataFim).format("DD/MM/YYYY")}
              </MenuItem>
              {dataMeses.map((mes, id) => {
                return (
                  <MenuItem
                    onClick={handlePopoverOpen}
                    key={id}
                    value={mes.inicio + "/" + mes.fim}
                  >
                    {moment(mes.inicio).format("DD/MM/YYYY")} A{" "}
                    {moment(mes.fim).format("DD/MM/YYYY")}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              select
              name="user"
              defaultValue={userDefault}
              helperText="Por favor selecione o usuário"
            >
              <MenuItem value={userDefault}>TODOS</MenuItem>
              {users.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.nome}
                </MenuItem>
              ))}
            </TextField>
            <Button
              style={{marginLeft: "30px"}}
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              endIcon={<SendIcon />}
            >
              Filtrar
            </Button>
            <Button
              style={{marginLeft: "100px"}}
              onClick={FecharTodosEspelhosPontos}
              className={classes.buttonActions}
              size="small"
            >
              Fechar Todos os Espelhos Pontos
            </Button>
          </div>
        </Box>
      </form>
    </div>
  );
}
