import React from "react";
import Button from "@material-ui/core/Button";
import {saveAs} from "file-saver";
import {green} from "@material-ui/core/colors";
import {withStyles} from "@material-ui/core/styles";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import XlsxPopulate from "xlsx-populate";

export default function XlsxPonto(props){
  const dataExcel = props.data;

  function getSheetData(data, header){
    let fields = header;
    let sheetData = data.map(function(row){
      return fields.map(function(fieldName){
        return row[fieldName] ? row[fieldName] : " ";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  function getDataAtual(){
    const dataHoje = new Date();
    let mesAtual = dataHoje.getMonth() + 1;
    let dataHojePrint =
      dataHoje.getDate() + "-" + mesAtual + "-" + dataHoje.getFullYear();
    return dataHojePrint;
  }

  async function saveAsExcel(){
    XlsxPopulate.fromBlankAsync().then(async workbook => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(dataExcel["data"], dataExcel["header"]);
      const totalColumns = sheetData[0].length;
      const totalLines = dataExcel["data"].length + 4;

      dataExcel["somaHorasExtras"].map((row, id) => {
        let i = 0;
        let line = totalLines + id;
        let retorno = row.split(";");
        sheet1.cell("A" + line).style("bold", true);
        sheet1
          .cell("A" + line)
          .value(retorno[0])
          .style({bold: true, border: true});
        sheet1.cell("B" + line).value(retorno[1]).style("border", true);
        i = i + 1;
      });

      sheet1.cell("A1").value(sheetData).style("border", true);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.range("A1:" + endColumn + "1").style("bold", true);
      return workbook.outputAsync().then(res => {
        saveAs(res, "historico_de_pontos_" + getDataAtual() + ".xlsx");
      });
    });
  }

  const ColorButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700]
      }
    }
  }))(Button);

  return (
    <ColorButton
      disabled={dataExcel["data"] == undefined ? true : false}
      color="primary"
      variant="contained"
      onClick={saveAsExcel}
      endIcon={<InsertDriveFileIcon />}
      style={{color: "white"}}
    >
      Download Excel
    </ColorButton>
  );
}
